<template>
  <div class="skeleton-block" />
</template>

<script>
export default {
  name: 'SkeletonBlock',
};
</script>

<style scoped lang="scss">
.skeleton-block {
  width: 100%;
  height: 100%;
  background-color: #dddbdd;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    animation: load 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  @keyframes load {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }
}
</style>

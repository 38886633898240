<template>
  <section class="top-ptable">
    <div class="top-ptable__header-container-ins">
      <template v-if="!isTeam"
        >Haz click en cualquier
        <template v-if="tournament == 1001"
          ><template v-if="category == 22">portera</template><template v-else>jugadora</template></template
        ><template v-else
          ><template v-if="category == 22">portero</template><template v-else>jugador</template></template
        ></template
      >
      <template v-else>Haz click en cualquier equipo </template>
    </div>
    <div class="top-ptable__header" :style="`background-color: #EDF4F9;`">
      <span :style="`color: ${customValues.color}`">POS</span>
      <span class="top-ptable__header__title" :style="`color: ${customValues.color}`">
        <template v-if="!isTeam"
          ><template v-if="tournament == 1001"
            ><template v-if="category == 22">PORTERA</template><template v-else>JUGADORA</template></template
          ><template v-else
            ><template v-if="category == 22">PORTERO</template><template v-else>JUGADOR</template></template
          ></template
        >
        <template v-else>EQUIPO</template>
      </span>
      <span :style="{ color: customValues.color, 'text-align': 'left;' }">TOTAL</span>
    </div>
    <div v-for="(player, i) in playerData" :key="i" class="top-ptable__row row-user">
      <span class="row-user__number"> {{ i + 1 + topValue * 5 }}. </span>
      <div class="row-user__info" @click="sendToProfile(player.id)">
        <img v-if="viewImage == indexPage" :src="player.img" :alt="player.name" />
        <span>{{ player.name }}</span>
      </div>
      <span class="row-user__total">
        <span class="row-user__total__item">{{ player.total }}</span>
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PlayerTable',
  props: {
    playerData: {
      type: Array,
      required: true,
    },
    viewImage: {
      type: Number,
      required: true,
    },
    category: {
      type: Number,
      require: true,
    },
    categoryName: {
      type: String,
      default: '',
    },
    topValue: {
      type: Number,
      required: true,
    },
    tournament: {
      type: Number,
    },
    indexPage: {
      type: Number,
      required: true,
    },
    customValues: {
      type: Object,
      default: () => {},
    },
    isTeam: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Number,
      default: 1401,
    },
  },
  methods: {
    getCategoryArray(type, cat) {
      const translate = {
        1: [...(this.isTeam ? ['54_r', '35_r'] : []), 12],
        2: [2, 20],
        3: [483],
        4: [10],
        5: [27],
        6: [510],
        7: [537, 540, 544, 547],
        8: [328],
        9: [231, 280, 238, 287, 406, 294],
        10: [13, 25],
        11: [98, 101, 104, 105, 109, 112, 115, 116],
        12: [4],
        13: [88],
        14: [5],
        15: [34],
        16: [23],
        17: [11],
        18: [17],
        19: [18],
        20: [14],
        21: [15],
        22: [39],
        23: [31, 32],
      };
      return translate[cat];
    },

    sendToProfile(id) {
      if (this.$route.name === 'viewAll') {
        if (this.isTeam) {
          this.$router.push({ name: 'teamProfile', query: { team: id, season: this.season, isLanding: true } });
        } else {
          this.$router.push({ name: 'playerProfile', query: { player: id, season: this.season, isLanding: true } });
        }
      } else {
        if (this.isTeam) {
          const routeData = this.$router.resolve({ name: 'teamprofile', query: { team: id, season: this.season } });
          window.open(routeData.href, '_blank');
        } else {
          const routeData = this.$router.resolve({ name: 'playerprofile', query: { player: id, season: this.season } });
          window.open(routeData.href, '_blank');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-row-selected {
  background-color: #eaffb1 !important;
}
.top-ptable {
  width: 100%;
  margin-bottom: 10px;
  &__header-container-ins {
    width: 100%;
    background: #fff;
    height: 60px;
    font-family: 'Circular-Std-Medium';
    color: #132839;
    font-size: 14px;
    text-align: center;
    line-height: 61px;
    @media screen and (max-width: 1328px) {
      height: 32px;
      line-height: 33px;
    }
  }
  &__header,
  &__row {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(25px, 30px) 1fr minmax(40px, 50px);
    border-radius: 2.4px;
    align-items: center;
  }
  &__row:nth-child(even) {
    background-color: #f5f5f5;
  }

  &__row:nth-child(odd) {
    background-color: #ffffff;
  }
  &__header {
    background-color: #dbefca;
    height: 24px;
    span {
      font-family: 'Avenir-Pro-Bold';
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -0.18px;
      text-align: center;
      color: #353535;
    }
    &__title {
      @media screen and (max-width: 1328px) {
        text-align: left !important;
        padding-left: 30px;
      }
      @media screen and (max-width: 628px) {
        text-align: center !important;
        padding-left: 0px;
      }
      @media screen and (max-width: 428px) {
        text-align: left !important;
        padding-left: 30px;
      }
      @media screen and (max-width: 340px) {
        text-align: center !important;
        padding-left: 0px;
      }
    }
  }
}
.row-user {
  color: #545454;
  height: 32px;
  &__number {
    font-family: 'Avenir-Medium';
    font-size: 13.2px;
    font-weight: 500;
    letter-spacing: -0.49px;
  }
  &__info {
    font-family: 'Avenir-Medium';
    font-size: 13px;
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 20px 1fr;
    column-gap: 10px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: underline;
    color: #132839;
    white-space: nowrap;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 80%;
      min-width: 220px;
    }
  }
  &__info:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &__total {
    font-family: 'Avenir-Pro-Bold';
    font-size: 13.2px;
    font-weight: bold;
    letter-spacing: -0.49px;
    text-align: center;
    padding-right: 0px;
  }
}
</style>

<template>
  <SkeletonBlock v-if="isMenuHidden" ref="skeletonBlock"></SkeletonBlock>
  <div v-else class="menu-top">
    <CarouselMini @selcategory="changeCategory" :categories="categoriesArray" :uphere="upHere" />
    <div class="menu-top__header-pc top-header-pc" :style="customValuesHeader2Background">
      <div class="top-header-pc__left-items"></div>
      <div class="top-header-pc__right-items">
        <template v-if="categorySelected != 5">
          <BField>
            <span :class="{ active: !isTeam }">Individual</span>
            <BSwitch
              color="#000000"
              passive-type="is-success"
              type="is-success"
              size="is-small"
              v-model="isTeam"
            ></BSwitch>
            <span :class="{ active: isTeam }">Equipo</span>
          </BField>
        </template>
      </div>
      <div class="top-header-pc__left-items"></div>
    </div>
    <div class="menu-top__header top-header" :style="customValuesHeaderBackground">
      <img :src="customValues.logo" alt="escudo_golstats" class="logo-img" />
      <div class="top-header__left-items"></div>
      <div class="top-header__right-items border-detail-bottom" :style="customValuesHeader2Background">
        <div class="top-header__right-items__background-cont">
          <template v-if="categorySelected != 5">
            <div class="container-filter-switch">
              <BField position="is-center">
                <span :class="{ active: !isTeam }">Individual</span>
                <BSwitch passive-type="is-success" type="is-success" size="is-small" v-model="isTeam"></BSwitch>
                <span :class="{ active: isTeam }">Equipo</span>
              </BField>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="container-menu-top-all">
      <div class="menu-top__body top-body" :class="{ blurred: !isPremiumUser && bluer }">
        <div class="top-body__table" @mouseover="upHere = true" @mouseleave="upHere = false">
          <template v-if="categoryData.length > 0 && topData.length > 0">
            <BCarousel
              :key="updateSlider"
              :autoplay="false"
              :pause-info="false"
              :arrow="false"
              :indicator-inside="false"
              @change="changeItem"
              v-model="indexSliderPlayerMiniSelected"
            >
              <BCarouselItem v-for="(carousel, i) in topData" :key="i">
                <section class="player-container">
                  <PlayerTableMini
                    :playerData="carousel"
                    :topValue="i"
                    :category="categorySelected"
                    :category-name="categoryName"
                    :indexPage="i"
                    :viewImage="viewImage"
                    :season="season"
                    :tournament="tournament"
                    :customValues="customValues"
                    :is-team="isTeam"
                  />
                </section>
              </BCarouselItem>
            </BCarousel>
            <div class="button-more" @click="buttonMore">Ver todas las categorias</div>
          </template>
          <template v-else>
            <template v-if="arePlaysEmpty">
              <div class="top-ptable__header" :style="`background-color: ${customValues.bgColor};`">
                <span :style="`color: ${customValues.color}`">POS</span>
                <span :style="`color: ${customValues.color}`">
                  <template v-if="!isTeam"
                    ><template v-if="tournament == 1001"
                      ><template v-if="categorySelected == 22">PORTERA</template
                      ><template v-else>JUGADORA</template></template
                    ><template v-else
                      ><template v-if="categorySelected == 22">PORTERO</template
                      ><template v-else>JUGADOR</template></template
                    ></template
                  >
                  <template v-else>EQUIPO</template>
                </span>
                <span :style="(`color: ${customValues.color}`, `text-align:left;`)">TOTAL</span>
              </div>
              <div class="container-not-found-data">
                <div class="container-not-found-data__img-search">
                  <img src="/images/graf_busqueda@2x.png" width="74" />
                </div>
                <div class="container-not-found-data__title-info">Sin resultados</div>
              </div>
            </template>
            <template v-else>
              <div class="top-ptable__header" :style="`background-color: ${customValues.bgColor};`">
                <span :style="`color: ${customValues.color}`">POS</span>
                <span :style="`color: ${customValues.color}`">
                  <template v-if="!isTeam"
                    ><template v-if="getTournamentSelected == 1001"
                      ><template v-if="categorySelected == 22">PORTERA</template
                      ><template v-else>JUGADORA</template></template
                    ><template v-else
                      ><template v-if="categorySelected == 22">PORTERO</template
                      ><template v-else>JUGADOR</template></template
                    ></template
                  >
                  <template v-else>EQUIPO</template>
                </span>
                <span :style="(`color: ${customValues.color}`, `text-align:left;`)">TOTAL</span>
              </div>
              <div v-for="index in 5" :key="index" class="placeholder-item" />
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PlayerTableMini from '@/components/Sections/Top/PlayerTableMini';
import CarouselMini from '@/components/Sections/Top/CarouselMini';
import SkeletonBlock from '@/components/Sections/Top/SkeletonBlock';
import { mapGetters, mapState } from 'vuex';
import { BField } from 'buefy/dist/esm/field';
import { BSwitch } from 'buefy/dist/esm/switch';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
export default {
  components: {
    PlayerTableMini,
    CarouselMini,
    SkeletonBlock,
    BField,
    BSwitch,
    BCarousel,
    BCarouselItem,
  },
  props: {
    logo: {
      type: String,
      default: '',
    },
    bluer: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Number,
    },
    tournament: {
      type: Number,
    },
    position: {
      type: Number,
      default: 1,
    },
    isPremiumUser: {
      type: Boolean,
      default: true,
    },
    custom: {
      type: Object,
      default: null,
    },
    isCup: {
      type: Boolean,
      default: false,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    upHere: false,
    indexSliderPlayerMiniSelected: 0,
    categorySelected: null,
    categoriesDefault: [
      { id: 1, name: 'Top 5 - Goles', bluer: false },
      { id: 2, name: 'Top 5 - Pases acertados', bluer: false },
      { id: 3, name: 'Top 5 - Balones recuperados en disputa', bluer: true },
      { id: 4, name: 'Top 5 - Asistencias', bluer: true },
      { id: 5, name: 'Top 5 - Participaciones en gol', bluer: true },
      { id: 6, name: 'Top 5 - Tiros a gol', bluer: true },
      { id: 7, name: 'Top 5 - Tiros a gol que llegaron a la portería', bluer: true },
      { id: 8, name: 'Top 5 - Cabeceos en área rival', bluer: true },
      { id: 9, name: 'Top 5 - Cabeceos en área propia', bluer: true },
      { id: 10, name: 'Top 5 - Centros', bluer: true },
      { id: 11, name: 'Top 5 - Centros acertados', bluer: true },
      { id: 12, name: 'Top 5 - 1vs1 exitosos ofensivos', bluer: true },
      { id: 13, name: 'Top 5 - 1vs1 exitosos ofensivos que terminaron en gol', bluer: true },
      { id: 14, name: 'Top 5 - 1vs1 defensivos exitoso', bluer: true },
      { id: 15, name: 'Top 5 - Rechaces', bluer: true },
      { id: 16, name: 'Top 5 - Balones ganados en área propia', bluer: true },
      { id: 17, name: 'Top 5 - Balones ganados en área rival', bluer: true },
      { id: 18, name: 'Top 5 - Faltas cometidas', bluer: true },
      { id: 19, name: 'Top 5 - Faltas recibidas', bluer: true },
      { id: 20, name: 'Top 5 - Tarjetas amarillas', bluer: true },
      { id: 21, name: 'Top 5 - Tarjetas rojas', bluer: true },
      { id: 22, name: 'Top 5 - Atajadas', bluer: true },
      { id: 23, name: 'Top 5 - Tiros de Esquina', bluer: true },
    ],
    categoriesArray: [],
    categoryData: [],
    url: 'https://kgfaxdv624.execute-api.us-east-2.amazonaws.com/prod/tops/seasons',
    urlFilter: 'https://cqqxaonvm6.execute-api.us-east-2.amazonaws.com/prod/tops/filters?categories=',
    viewImage: 0,
    typeSelected: 1,
    filterActive: false,
    topsTeams: {},
    topsPlayers: {},
    playersInfo: {},
    teamInfo: {},
    filtersSelect: {
      playingAs: [],
      matchResult: [],
      sections: [],
      zone: [],
      typeZone: [],
    },
    updateSlider: 0,
    isTeam: false,
    formatStats: [],
    seasonSelected: null,
    formatFilterStats: [],
    openLayer: false,
    openDrawer: false,
    customValues: {
      logo: '',
      bgColor: '#EDF4F9',
      color: '#fffff',
    },
    customValuesHeaderBackground: {
      background: '#dbefca',
    },
    customValuesHeader2Background: {
      background: '#3b3b3b',
      color: '#FFFFFF',
    },
    customValuesHeaderColor: {
      color: '#cbee6a',
    },
    data_headers: ['team_id', 'player_id', 'playing_as', 'match_result', 'section', 'total', 'origen', 'destino'],
    isMenuHidden: true,
    arePlaysEmpty: false,
    intersectionObserverCounter: 0,
    isVisible: false,
    playersImagesUrl: 'https://az755631.vo.msecnd.net/players-profile/',
  }),
  computed: {
    ...mapGetters('loginGeneral', ['getUser', 'getTournamentSelected', 'getSeasonSelected']),
    ...mapState('profileGeneral', ['colorsConfig']),
    ...mapState({ windowWidth: 'windowWidth' }),
    topData() {
      var arrayTopsData = [];
      if (this.categorySelected != null) {
        if (this.categoryData[this.categorySelected] !== undefined) {
          if (this.isTeam) {
            arrayTopsData = this.formatArrayPaginate(this.categoryData[this.categorySelected].top__teams);
          } else {
            arrayTopsData = this.formatArrayPaginate(this.categoryData[this.categorySelected].top__players);
          }
        }
      }

      return arrayTopsData;
    },
    categoryName() {
      let categoryName = '';
      const category = this.categoriesDefault.find(category => category.id === this.categorySelected);
      if (category) {
        const nameArray = category.name.split(' - ');
        categoryName = nameArray.pop();
      }
      return categoryName;
    },
  },
  watch: {
    topData() {
      var exist = true;
      if (exist) {
        this.indexSliderPlayerMiniSelected = 0;
      }
    },
    isTeam() {
      if (this.isTeam) {
        this.typeSelected = 0;
        this.indexSliderPlayerMiniSelected = 0;
        this.viewImage = 0;
      } else {
        this.typeSelected = 1;
        var exist = true;
        if (exist) {
          this.indexSliderPlayerMiniSelected = 0;
        }
        this.viewImage = this.indexSliderPlayerMiniSelected;
      }

      this.updateSliderF();
    },
    custom: {
      immediate: true,
      handler: function(newValue) {
        if (newValue != null) {
          if (Object.keys(newValue).length) {
            this.customValues.logo = newValue.logo;
            this.customValues.bgColor = newValue.subHeaderBackground;
            this.customValues.color = newValue.colorheader;
            this.customValuesHeaderBackground.background = newValue.headerBackground;
            this.customValuesHeaderBackground.color = newValue.headerColor;
            this.customValuesHeaderBackground.borderColor = newValue.headerBorderColor;
            this.customValuesHeader2Background.borderColor = newValue.headerBorderColor;
            this.customValuesHeader2Background.background = newValue.headerBackground;
            this.customValuesHeader2Background.color = newValue.headerColor;
          }
        }
      },
    },
    season: {
      immediate: true,
      handler: function(newValue) {
        if (newValue != null) {
          if (!newValue) return;
          this.seasonSelected = this.season;
          const wasMenuHidden = this.isMenuHidden;
          this.isMenuHidden = true;
          this.getCategoryStats();
          if (!wasMenuHidden) {
            this.intersectionObserverCounter = 0;
            this.isVisible = false;
            setTimeout(() => {
              this.setUpIntersectionObserver();
            }, 500);
          } else if (this.isVisible) {
            this.isMenuHidden = false;
          }
        }
      },
    },
  },
  methods: {
    async changeCategory(category) {
      try {
        if (this.categoryData[category.id] !== undefined) {
          this.categorySelected = category.id;
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    buttonMore() {
      this.$router.push({
        name: 'topsSection',
        // query: {
        //   isCup: this.isCup,
        //   seasonId: this.season,
        //   team: this.isWidget ? this.$route.params.team : '',
        // },
      });
    },
    changeItem(value) {
      this.viewImage = value;
    },

    async getCategoryStats() {
      try {
        if (!this.seasonSelected) return;
        let teamData = {};
        let playersData = {};
        let TopsData = [];
        this.arePlaysEmpty = false;
        const data = await axios
          .get(this.url + '/' + this.seasonSelected)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getCategoryStats(this.seasonSelected);
            }
            return e;
          });
        if (data && data.data) {
          data.data.teams.map(data => {
            teamData[data[0]] = {};
            teamData[data[0]]['id'] = data[0];
            teamData[data[0]]['name'] = data[1];
            teamData[data[0]]['img'] = data[3];
            teamData[data[0]]['abrv'] = data[2];
          });
          data.data.players.map(data => {
            playersData[data[0]] = {};
            playersData[data[0]]['id'] = data[0];
            playersData[data[0]]['name'] = data[1];
            playersData[data[0]]['img'] = data[2];
          });

          data.data.tops.map(data => {
            TopsData[data.category] = {};
            var topTeam = {};
            var topPlayer = {};
            data.teams.map(data => {
              topTeam[data[0]] = {};
              topTeam[data[0]]['total'] = data[1];
              topTeam[data[0]]['id'] = teamData[data[0]].id;
              topTeam[data[0]]['name'] = teamData[data[0]].name;
              topTeam[data[0]]['img'] = 'https://az755631.vo.msecnd.net/teams-80/' + data[0] + '.png';
            });
            data.players.map(data => {
              topPlayer[data[0]] = {};
              topPlayer[data[0]]['total'] = data[1];
              topPlayer[data[0]]['name'] = playersData[data[0]].name;
              topPlayer[data[0]]['img'] = playersData[data[0]].img;
              topPlayer[data[0]]['id'] = playersData[data[0]].id;
            });
            var sortTopTeam = [];
            var sortTopPlayer = [];
            for (const idTeam in topTeam) {
              sortTopTeam.push(topTeam[idTeam]);
            }
            for (const idPlayer in topPlayer) {
              sortTopPlayer.push(topPlayer[idPlayer]);
            }
            sortTopTeam.sort((a, b) => (a.total < b.total ? 1 : -1));
            sortTopPlayer.sort((a, b) => (a.total < b.total ? 1 : -1));
            TopsData[data.category]['top__players'] = sortTopPlayer;
            TopsData[data.category]['top__teams'] = sortTopTeam;

            // if (this.getUser.player != 0) {
            //   for (
            //     let categoryIndex = 0;
            //     categoryIndex < TopsData[data.category]['top__players'].length;
            //     categoryIndex++
            //   ) {
            //     var isTop = false;
            //     if (categoryIndex < 5) {
            //       if (TopsData[data.category]['top__players'][categoryIndex].id == this.getUser.player) {
            //         isTop = true;
            //       }
            //     }
            //     if (isTop) {
            //       this.categoriesArray.push({ id: data.category, name: 'Top 5 - ' + data.name, bluer: false });
            //     }
            //   }
            // }
          });
        } else {
          this.arePlaysEmpty = true;
        }
        // if (this.getUser.player != 0) {
        //   for (let index = 0; index < this.categoriesDefault.length; index++) {
        //     const found = this.categoriesArray.find(x => x.id === this.categoriesDefault[index].id);
        //     if (!found) {
        //       this.categoriesArray.push(this.categoriesDefault[index]);
        //     }
        //   }
        // } else {
        this.categoriesArray = [...this.categoriesDefault];
        // }
        this.categorySelected = this.categoriesArray[0].id;
        this.categoryData = TopsData;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    formatArrayPaginate(arrayData) {
      let num = parseInt(arrayData.length / 5);
      let mod = arrayData.length % 5;
      if (mod > 0) {
        num = num + 1;
      }
      var arrayPlayers = [];
      for (let index = 1; index <= num; index++) {
        var items = index * 5;
        arrayPlayers[index - 1] = arrayData.slice(items - 5, items);
      }
      return arrayPlayers;
    },
    updateSliderF() {
      this.updateSlider += 1;
    },
    setUpIntersectionObserver() {
      if (!window.IntersectionObserver) {
        this.isMenuHidden = false;
        return;
      }
      const observer = new IntersectionObserver(entries => {
        this.intersectionObserverCounter++;
        if (entries[0].intersectionRatio > 0 || this.intersectionObserverCounter === 2) {
          observer.unobserve(this.$refs.skeletonBlock.$el);
          if (this.seasonSelected) {
            this.isMenuHidden = false;
          } else {
            this.isVisible = true;
          }
        }
      });
      observer.observe(this.$refs.skeletonBlock.$el);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setUpIntersectionObserver();
    });
  },
};
</script>
<style lang="scss" scoped>
.button-more {
  width: 180px;
  height: 30px;
  margin: 0 auto;
  margin-top: 27px;
  margin-bottom: 20px;
  font-family: 'Avenir-Medium';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: -0.28px;
  text-align: center;
  color: #000;
  border-radius: 8px;
  border: solid 2px #e3eaf0;
  @media screen and (max-width: 400px) {
    width: 224px;
    height: 38px;
    line-height: 38px;
  }
}
.button-more:hover {
  opacity: 0.8;
  cursor: pointer;
}
.text-bold-overlay {
  font-size: 27px;
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
}
.subtext-bold-overlay {
  font-size: 19px;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
}
.container-menu-top-all {
  overflow: hidden;
}
.div-centyer-text {
  width: 100%;
  margin-top: 44%;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  position: relative;
}
$blur: 10px;
.blurred {
  filter: blur($blur);
  -webkit-filter: blur($blur);
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 0;
}
.table-overlay-container {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 450px;
  overflow: visible;
  position: absolute;
}
@mixin table-overlay {
  width: 94%;
  height: 94%;
  margin: 0 auto;
  margin-top: 10px;
  font-family: 'Circular-Std-Medium';
  border: dotted 2px #5c5c5c;
  color: #3e3e3e;
  border-radius: 8px;
  display: table;
  text-align: center;
  position: relative;
}

@mixin font-circular-bold {
  font-family: 'Circular-Std-Black';
  color: #272727f5;
}

.table-overlay {
  &--mobile {
    @include table-overlay;
    & > b {
      @include font-circular-bold;
    }
  }
  &--desktop {
    @include table-overlay;
    @media screen and (min-width: 1280px) {
      & > b {
        @include font-circular-bold;
      }
    }
  }

  &--mobile,
  &--desktop {
    transition: width 50ms;
    transition-timing-function: linear;
  }
}
.border-detail-top {
  border-bottom: 2px solid #88da69;
}
.border-detail-bottom {
  @media screen and (max-width: 500px) {
    border-top: 2px solid #88da69;
  }
}
.container-not-found-data {
  width: 100%;
  border-radius: 5px;
  height: 372px;

  // border: 2px dashed #dadada;
  display: inline-block;
  @media screen and (max-width: 628px) {
    height: 250px;
  }
  &__img-search {
    width: 100%;
    margin-top: 90px;
  }
  &__title-info {
    width: 100%;
    margin-top: 14px;
    text-align: center;
    font-family: 'Circular-Std';
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.52px;
  }
  &__subtitle-info {
    width: 100%;
    text-align: center;
    margin-top: 4px;
    font-family: 'Avenir-Roman';
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.12px;
    color: #9a9a9a;
  }
}
.close-button {
  cursor: pointer;
  position: absolute;
  font-size: 24px;
  letter-spacing: -0.04px;
  text-align: center;
  background-color: #494a4b;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 1.6em;
  padding-top: 8px;
  display: none;
  margin-left: 23px;
  margin-top: 5px;
  border-radius: 4px;
}
.color-gray {
  background-color: #3b3b3b !important;
}
.menu-top {
  width: 100%;
  display: inline-block;
  position: relative;
  border-radius: 2px;
  border: solid 1.1px #c0c0c0;
  height: fit-content;
  @media screen and (max-width: 1328px) {
    height: 480px;
  }
  @media screen and (max-width: 628px) {
    height: fit-content;
  }
  &__header {
    border-radius: 2px;
    background-color: #242424;
    padding: 7px 0px 0px 0px;
    position: relative;
    display: flex;
    align-items: center;
    height: 34px;
    @media screen and (max-width: 500px) {
      flex-flow: column;
      height: unset;
    }
  }
  &__header-pc {
    background-color: #242424;
    padding: 7px 0px;
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    border-top: 2px solid #cbfb48;
  }
  &__body {
    display: inline-flex;
  }
  &__drawer {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    justify-content: flex-end;
    display: none;
    height: 425px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    margin: 262px 0px;
    padding: 0px;
    @media screen and (max-width: 500px) {
      height: 455px;
    }
    @media screen and (max-width: 370px) {
      margin: 259px 0px;
    }

    &.active {
      @media screen and (max-width: 500px) {
        display: flex;
      }
    }
    .filter-drawer {
      background-color: white;
      width: 0px;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      opacity: 0;
      position: relative;
      &.active {
        width: 100%;
        opacity: 1;
        height: 455px;
      }

      .close-btn {
        position: absolute;
        top: 10px;
        left: 21px;
        width: 25px;
        height: 25px;
        color: white;
        display: flex;
        justify-content: center;
        border-radius: 4px;
        background-color: #3b3b3b;
        align-items: center;
        font-size: 21px;
        cursor: pointer;
        line-height: 19px;
        outline: none;
        border: none;
      }
    }
  }
  &__footer {
    border-radius: 2px;
    min-height: 40px;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07);
    background-color: #fbfbfb;
    display: flex;
    justify-content: center;
    align-items: center;
    .spacer {
      width: 1px;
      height: 0.75rem;
      background-color: #979797;
      margin: 0 16px;
    }
  }
}
.top-header-pc {
  .logo-img {
    position: absolute;
    left: 11px;
    width: 28px;
  }
  &__left-items {
    margin-right: auto;
  }
  &__title {
    font-family: 'Circular-Std-Medium';
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.52px;
    text-align: center;
    color: #ffffff;
    padding-left: 0px;
    position: absolute;
    right: 0;
    left: 0;
    @media screen and (max-width: 560px) {
      font-size: 18px;
    }
  }

  &__right-items {
    font-family: 'Circular-Std-Medium';
    font-size: 11px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    padding-top: 1px;
    color: #ffffff;

    span {
      padding-right: 10px;
      font-size: 12px;
      color: white;
      opacity: 0.7;

      &.active {
        opacity: 1;
      }
    }
  }
}
.top-header {
  display: none;
  .logo-img {
    position: absolute;
    left: 15px;
    width: 18px;
    @media screen and (max-width: 500px) {
      top: 3px;
    }
  }
  &__left-items {
    margin-right: auto;
  }
  &__adv-btn {
    display: none;
    @media screen and (max-width: 500px) {
      outline: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      height: 25px;
      margin: 12px 0px;
      border-radius: 7px;
      background-color: #6abe0f;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }
    img {
      margin-left: 5px;
      width: 12px;
    }
  }
  &__title {
    font-family: 'Circular-Std-Medium';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.52px;
    text-align: center;
    color: #ffffff;
    padding-left: 10px;
    position: absolute;
    right: 0;
    left: 0;

    @media screen and (max-width: 500px) {
      position: initial;
    }
    @media screen and (max-width: 370px) {
      font-size: 12px;
      height: 16px;
    }
  }
  &__right-items {
    font-family: 'Circular-Std-Medium';
    font-size: 11px;
    font-weight: 500;
    width: 100%;
    height: 30px;
    background: #3b4044;
    padding-top: 5px;
    text-align: center;
    padding-top: 1px;
    color: #ffffff;

    span {
      padding-right: 10px;
    }

    &__background-cont {
      font-family: 'Circular-Std-Medium';
      font-size: 11px;
      font-weight: 500;
      text-align: center;
      display: inline-block;
      padding-top: 5px;
      color: #ffffff;
      margin: 0 auto;
    }
  }
}
.top-body {
  width: 100%;
  min-height: 328px;
  &__table {
    width: 100%;
  }
  &__filters {
    padding: 4px 0px;
    width: 100%;
    display: flex;
  }
}
.placeholder-item:nth-of-type(odd) {
  background-color: #f5f5f5;
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.05);
}
.placeholder-item {
  height: 32px;
  position: relative;
  overflow: hidden;
}
.placeholder-item:nth-of-type(odd)::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
.top-ptable {
  width: 100%;
  margin-bottom: 10px;
  &__header {
    display: grid;
    grid-template-columns: minmax(25px, 30px) 1fr minmax(40px, 60px);
    border-radius: 2.4px;
    text-align: center;
    align-items: center;
  }
  &__header {
    background-color: #dbefca;
    height: 24px;
    span {
      font-family: 'Avenir-Pro-Bold';
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -0.18px;
      text-align: center;
      color: #353535;
    }
  }
}
.container-filter-switch {
  span {
    padding-right: 10px;
    font-size: 12px;
    color: white;
    opacity: 0.7;

    &.active {
      opacity: 1;
      font-weight: bold;
    }
  }
}
</style>
<style lang="scss">
$green: #cbee6b;
.carousel-categories .carousel-arrow .icon {
  opacity: 1 !important;
}
.menu-top {
  .switch input[type='checkbox'] + .check.is-success-passive,
  .switch input[type='checkbox'] + .check:hover {
    background: $green !important;
    border-color: $green !important;
  }

  .b-radio.radio input[type='radio']:checked + .check.is-success {
    background: $green !important;
    border-color: #95dc28 !important;
  }
  .b-radio.radio input[type='radio'] + .check.is-success:before {
    background: $green !important;
  }
  .checkbox input[type='checkbox']:checked + .check.is-success {
    border-color: $green !important;
    background: $green
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:white' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center center !important;
  }
  @media screen and (min-width: 1328px) {
    .carousel-indicator {
      margin-top: 40px;
    }
  }
  .top-body__table .carousel .carousel-indicator .indicator-item .indicator-style {
    background-color: #e0e0e0;
    border: none;
  }
  .top-body__table .carousel .carousel-indicator .indicator-item.is-active .indicator-style,
  .carousel .carousel-indicator .indicator-item .indicator-style:hover {
    background: $green;
  }
}
</style>

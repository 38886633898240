<template>
  <div class="carousel-categories-topmini">
    <BCarousel
      :interval="5000"
      :autoplay="uphere ? false : true"
      :indicator-inside="false"
      :indicator="false"
      :pause-info="true"
      :progress="false"
      @change="changeItemCategory"
      v-model="categorySelected"
    >
      <BCarouselItem v-for="(carousel, i) in categories" :key="i">
        <section class="categories-container-topmini" @click="clickOpenModal">
          {{ carousel.name }}
        </section>
      </BCarouselItem>
    </BCarousel>
    <div class="modal-categories" v-if="openModal">
      <div class="modal-categories__container">
        <template v-for="(category, i) in categories">
          <li :key="i" class="item-category-modal-mini" @click="modalCategorySelected(i)">
            {{ category.name.replace(/Top 5 -/g, '') }}
          </li>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
export default {
  name: 'CarouselMini',
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    uphere: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BCarousel,
    BCarouselItem,
  },
  data: () => ({
    openModal: false,
    categorySelected: 0,
  }),
  methods: {
    modalCategorySelected(index) {
      this.categorySelected = index;
      this.openModal = !this.openModal;
    },
    clickOpenModal() {
      this.openModal = !this.openModal;
    },
    changeItemCategory(index) {
      this.categorySelected = index;
      this.$emit('selcategory', this.categories[index]);
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.item-category-modal-mini {
  width: 97%;
  height: 26px;
  line-height: 26px;
  font-family: 'Avenir-Pro-Medium';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.28px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #132739;
  margin-top: 1px;
  margin-bottom: 1px;
}
.item-category-modal-mini:hover {
  background: rgba(207, 207, 207, 0.7);
  cursor: pointer;
}
.modal-categories {
  width: 76%;
  height: 224px;
  position: absolute;
  z-index: 1;
  top: 41px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 10px 20px 15px;
  border-radius: 12px;
  box-shadow: 0 24px 38px 0 rgba(36, 60, 87, 0.25);
  background-color: #fff;
  &__container {
    overflow: auto;
    height: 188px;
  }
}
.carousel-categories-topmini {
  width: 100%;
  height: 41px;
  background: #132739;
  position: relative;
}
.categories-container-topmini {
  width: 72%;
  line-height: 41px;
  margin: 0 auto;
  font-family: 'Circular-Std-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.36px;
  text-align: center;
  color: #cbee6a;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
}
.categories-container-topmini:hover {
  cursor: pointer;
  background: #142e45;
}
.carousel-categories-topmini .carousel-indicator {
  display: none;
}
.carousel-categories-topmini .carousel-arrow .icon.has-icons-right {
  right: 1% !important;
}
.carousel-categories-topmini .carousel-arrow .icon.has-icons-left {
  left: 1% !important;
}
.carousel-categories-topmini .carousel-arrow.is-hovered {
  opacity: 1 !important;
}
.carousel-categories-topmini .carousel-arrow .icon {
  width: 35px;
  height: 35px;
  opacity: 0.5;
  border-radius: 8px;
  background-color: rgba(37, 69, 95, 0.8);
  border: 1px solid #25455f;
}
.carousel-categories-topmini .carousel-arrow .icon:hover {
  border: 1px solid #7e7e7e;
}
.carousel-categories-topmini .carousel-arrow .icon {
  color: white;
}
</style>
